import classNames from 'classnames';
import React, { ComponentProps } from 'react';
import { Layout } from '../../../../../components';
import styles from './Header.module.scss';

export type HeaderProps = ComponentProps<'header'>;

export const Header = ({ className, ...props }: HeaderProps) => {
    return (
        <Layout.Section asChild padded>
            <Layout.Header {...props} className={classNames(styles.wrapper, className)}>
                <Layout.Title>Extração de Preços</Layout.Title>
            </Layout.Header>
        </Layout.Section>
    );
};
