import React, { ComponentProps } from 'react';
import ModalNotification from '../../../../../components/ModalNotification';

export type ModalsProps = ComponentProps<'div'>;

export const Modals = () => {
    const [showLineLimitModal, setShowLineLimitModal] = React.useState(false);

    return (
        <>
            <ModalNotification
                show={showLineLimitModal}
                onHide={() => setShowLineLimitModal(false)}
                title="Extração de dados"
                message="O arquivo que você deseja baixar contém mais de
                cinquenta mil linhas e pode demorar um pouco para ficar pronto. Te enviaremos por e-mail assim que estiver tudo certo!"
            />
        </>
    );
};
