export const sortOptionsColumnMap: Record<string, string> = {
    price: 'price',
    priceRange: 'price',
    cpi: 'cpi',
    cpiRange: 'cpi',
    newMargin: 'newMargin',
    newMarginRange: 'newMargin',
};

const options = {
    price: [
        {
            value: 'price',
            label: 'Preço sugerido',
        },
        {
            value: 'priceRange',
            label: 'Variação de preço',
        },
    ],
    cpi: [
        {
            value: 'cpi',
            label: 'Nova competitividade',
        },
        {
            value: 'cpiRange',
            label: 'Variação de competitividade',
        },
    ],
    newMargin: [
        {
            value: 'newMargin',
            label: 'Nova margem',
        },
        {
            value: 'newMarginRange',
            label: 'Variação de margem',
        },
    ],
} as const;

export default options;
