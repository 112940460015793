import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PriceExtraction } from '../../../../@types/IPA/PriceExtraction';
import { RootState } from '../../../../@types/RootState';

type RowData = PriceExtraction['rowData'];
type State = RowData[];

const initialState: State = [];

const slice = createSlice({
    name: 'ipa.extraction.table.datapoints',
    initialState,
    reducers: {
        RESET_EXTRACTION_DATAPOINTS: () => initialState,
        SET_EXTRACTION_DATAPOINTS: (_, action: PayloadAction<State>) => {
            return action.payload;
        },
    },
});

export const { RESET_EXTRACTION_DATAPOINTS, SET_EXTRACTION_DATAPOINTS } = slice.actions;

export default slice.reducer;

export const selectorExtractionDatapoints = (state: RootState) => {
    return state.ipa.extraction.table.datapoints;
};
