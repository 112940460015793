import classNames from 'classnames';
import React, { forwardRef } from 'react';
import styles from './CustomHeaderCell.module.scss';
import { CustomHeaderCellContentDataList, CustomHeaderCellContentDataListProps } from './CustomHeaderCellContentDataList';
import { CustomHeaderCellContentHeader } from './CustomHeaderCellContentHeader';

export type CustomHeaderCellContentProps = CustomHeaderCellContentDataListProps;

const CustomHeaderCellContent = forwardRef<HTMLDivElement, CustomHeaderCellContentProps>(({ className, ...props }, ref) => {
    return (
        <div ref={ref} className={classNames(styles.content, className)}>
            <CustomHeaderCellContentHeader />
            <CustomHeaderCellContentDataList {...props} />
        </div>
    );
});

CustomHeaderCellContent.displayName = 'CustomHeaderCellContent';

export { CustomHeaderCellContent };
