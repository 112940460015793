import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../@types/RootState';

export type GerenciadorDatakeysState = Record<string, string>;

const initialState: GerenciadorDatakeysState = {
    price: 'price',
    cpi: 'cpi',
    newMargin: 'newMargin',
};

const slice = createSlice({
    name: 'ipa/gerenciador/datakeys',
    initialState,
    reducers: {
        UPDATE_GERENCIADOR_DATAKEY(state, action: PayloadAction<Partial<GerenciadorDatakeysState>>) {
            return _.merge({}, state, action.payload);
        },
        SET_GERENCIADOR_DATAKEY(_, action: PayloadAction<GerenciadorDatakeysState>) {
            return action.payload;
        },
        RESET_GERENCIADOR_DATAKEY() {
            return initialState;
        },
    },
});

export const { UPDATE_GERENCIADOR_DATAKEY, SET_GERENCIADOR_DATAKEY, RESET_GERENCIADOR_DATAKEY } = slice.actions;

export default slice.reducer;

export const selectorGerenciadorDatakeys = (state: RootState) => state.gerenciadorPrecosReducer.datakeys;
