import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../@types/RootState';
import { TableHeading as ITableHeading } from '../../../../../components';
import { TableHeadingProps } from '../../../../../components/TableHeading/TableHeading.types';
import { DownloadButton } from '../DownloadButton';

const { Container, TotalProducts } = ITableHeading;

const TableHeading = (props: TableHeadingProps) => {
    const total = useSelector((state: RootState) => {
        return state.ipa.extraction.table.pagination.total;
    });

    return (
        <ITableHeading {...props}>
            <Container>
                <TotalProducts total={total} />
            </Container>
            <Container>
                <DownloadButton />
            </Container>
        </ITableHeading>
    );
};

export { TableHeading };
