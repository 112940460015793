export class LocalStorage {
    static set<T = unknown>(key: string, value: T) {
        window.localStorage.setItem(key, JSON.stringify(value));
    }

    static get<T = unknown>(key: string): T | null {
        const value = window.localStorage.getItem(key);
        return value ? JSON.parse(value) : null;
    }

    static update<T = unknown>(key: string, value: Partial<T>) {
        const currentValue = LocalStorage.get<T>(key);
        if (currentValue) LocalStorage.set(key, { ...currentValue, ...value });
    }
}
