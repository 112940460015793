import classNames from 'classnames';
import React, { ComponentProps, forwardRef } from 'react';
import { MdUnfoldMore } from 'react-icons/md';
import styles from './CustomHeaderCell.module.scss';

type CustomHeaderCellContentHeaderProps = ComponentProps<'span'>;

const CustomHeaderCellContentHeader = forwardRef<HTMLSpanElement, CustomHeaderCellContentHeaderProps>(({ className, ...props }, ref) => {
    return (
        <span {...props} ref={ref} className={classNames(styles['content-header'], className)}>
            <MdUnfoldMore size={14} />
            <h5>ORDERNAR POR</h5>
        </span>
    );
});

CustomHeaderCellContentHeader.displayName = 'CustomHeaderCellContentHeader';

export { CustomHeaderCellContentHeader };
