import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../@types/RootState';

export type ExtractionPaginationState = {
    activePage: number;
    displayLength: number;
    total: number;
    lengthMenu: Record<'label' | 'value', number>[];
};

const lengthMenu: ExtractionPaginationState['lengthMenu'] = [
    {
        value: 20,
        label: 20,
    },
    {
        value: 40,
        label: 40,
    },
];

const initialState: ExtractionPaginationState = {
    activePage: 1,
    displayLength: lengthMenu[0].value,
    total: 0,
    lengthMenu,
};

const slice = createSlice({
    name: 'ipa.extraction.table.datapoints',
    initialState,
    reducers: {
        RESET_EXTRACTION_DATAPOINTS: () => initialState,
        UPDATE_EXTRACTION_PAGE: (state, action: PayloadAction<number>) => {
            return { ...state, activePage: action.payload };
        },
        UPDATE_EXTRACTION_PAGE_SIZE: (state, action: PayloadAction<number>) => {
            return { ...state, displayLength: action.payload };
        },
        UPDATE_EXTRACTION_TOTAL: (state, action: PayloadAction<number>) => {
            return { ...state, total: action.payload };
        },
    },
});

export const { RESET_EXTRACTION_DATAPOINTS, UPDATE_EXTRACTION_PAGE, UPDATE_EXTRACTION_PAGE_SIZE, UPDATE_EXTRACTION_TOTAL } = slice.actions;

export default slice.reducer;

export const selectorExtractionPagination = (state: RootState) => {
    return state.ipa.extraction.table.pagination;
};
