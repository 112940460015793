import { useMutation } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../@types/RootState';
import { ButtonPrimary } from '../../../../../components';
import { selectorIpaExtractionFiltersValues } from '../../../../../reducers/ipa/extraction/filters/values';
import { datapointsDownload } from '../../services';
import { GET_extractionDatapointsMapper } from '../Table/utils';

export const DownloadButton = () => {
    const total = useSelector((state: RootState) => {
        return state.ipa.extraction.table.pagination.total;
    });

    const value = useSelector(selectorIpaExtractionFiltersValues);

    const params = useMemo(() => {
        return GET_extractionDatapointsMapper({
            value,
        });
    }, [value]);

    const { mutate } = useMutation({
        mutationFn: datapointsDownload,
    });

    return (
        <ButtonPrimary onClick={() => mutate({ params })} disabled={!total}>
            DOWNLOAD
        </ButtonPrimary>
    );
};
