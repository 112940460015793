import { Slot } from '@radix-ui/react-slot';
import classNames from 'classnames';
import React, { HtmlHTMLAttributes } from 'react';
import { BaseComponentProps } from '../../@types/BaseComponent';
import styles from './Layout.module.scss';

export type LayoutProps = HtmlHTMLAttributes<HTMLElement>;

const Layout = ({ className, ...props }: LayoutProps) => <main className={classNames(styles['page-layout'], className)} {...props} />;

export type LayoutHeaderProps = HtmlHTMLAttributes<HTMLElement>;

const Header = ({ className, ...props }: LayoutHeaderProps) => <header className={classNames(styles['page-header'], className)} {...props} />;

export type TitleProps = HtmlHTMLAttributes<HTMLHeadingElement>;

const Title = ({ children, className, ...props }: TitleProps) => (
    <h1 className={classNames(styles['page-title'], className)} {...props}>
        {children}
    </h1>
);

export type SubtitleProps = HtmlHTMLAttributes<HTMLHeadingElement>;

const Subtitle = ({ children, className, ...props }: SubtitleProps) => (
    <h4 className={classNames(styles['page-subtitle'], className)} {...props}>
        {children}
    </h4>
);

export type BodyProps = HtmlHTMLAttributes<HTMLDivElement> & {
    asChild?: boolean;
};

const Body = ({ className, asChild, ...props }: BodyProps) => {
    const Comp = asChild ? Slot : 'div';
    return <Comp className={classNames(styles['page-body'], className)} {...props} />;
};
export type SectionProps = HtmlHTMLAttributes<HTMLElement> & {
    padded?: boolean;
};

const Section = ({ padded, className, asChild, ...props }: BaseComponentProps<SectionProps>) => {
    const Comp = asChild ? Slot : 'section';

    return <Comp className={classNames(styles['page-section'], padded && styles['page-section-padded'], className)} {...props} />;
};

Layout.Header = Header;
Layout.Title = Title;
Layout.Subtitle = Subtitle;
Layout.Body = Body;
Layout.Section = Section;

export { Layout };
