import React from 'react';
import {
    Button,
    Icon,
    IconButton,
    Modal,
    Notification,
} from 'rsuite';

const ModalGtinIvalido = ({
    show, gtins, totalGtins, onConfirm, onCancel,
}) => {
    const copyGtins = () => {
        navigator.clipboard.writeText(gtins.join('\n'));
        Notification.open({
            placement: 'topEnd',
            description: 'Código(s) copiado(s) com sucesso!',
            duration: 2000,
            className: 'nova-lista-copy-notification',
        });
    };

    return (

        <Modal backdrop size="md" show={show} className="modal-gtin-invalido">
            <Modal.Body>
                <div className="header">
                    <h1>Os seguintes Códigos de barras e/ou Palavras-chaves não são válidos</h1>
                    <p>
                        Os códigos e/ou palavras abaixo estão gerando algum conflito de sistema, isso significa que não podem ser solicitados. 
                        Corrija os códigos ou envie as solicitações válidas.
                    </p>
                </div>
                <div className="content">
                    <div>
                        {gtins.map((gtin) => (
                            <>
                                <span key={{ gtin }}>
                                    {gtin}

                                </span>
                                <br />
                            </>
                        ))}
                    </div>
                    <p className="content__subtitle">
                        {gtins.length}
                        {' '}
                        de
                        {' '}
                        {totalGtins}
                        {' '}
                        códigos de produto informados são inválidos
                    </p>

                    <IconButton
                        icon={<Icon icon="copy" />}
                        onClick={() => copyGtins()}
                        appearance="link"
                    >
                        Copiar a lista de códigos inválidos
                    </IconButton>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button appearance="ghost" onClick={() => onCancel()}>Corrigir códigos</Button>
                <Button appearance="primary" onClick={() => onConfirm()}>Prosseguir sem inválidos</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalGtinIvalido;
