import { createSlice } from '@reduxjs/toolkit';
import { snakeCaseToCamelCase } from '../../utils/snakeCaseToCamelCase';

export type BaseFields = {
    price: number;
    margin: number;
    competitiveness: number;
    demandForecast: number;
    revenueForecast: number;
    profitForecast: number;
    marginLevel1: number;
    marginLevel2: number;
};

export type OutputSimulation = {
    elasticity: number | null;
    basePriceScenario: BaseFields;
    newPriceScenario: BaseFields;
    scenariosVariation: BaseFields;
};

export type Query = {
    productId: string;
    productName: string;
    storeIds: string[];
    fromDate: string;
    toDate: string;
    newPrice: number;
    client: string;
    basePrice: number;
};

export interface Scenario {
    id: string | null;
    scenarioName: string | null;
    user: string | null;
    username: string | null;
    client: string | null;
    date: string | null;
}

export type OutputAreaProps = {
    simulation: OutputSimulation[];
    output: OutputSimulation;
    query: Query;
    scenario: Scenario;
    calculations: {
        hasElasticity: boolean;
        hasSimulation: boolean;
        isSearchDone: boolean;
    };
};
const initialState: OutputAreaProps = {
    simulation: [],
    output: {
        elasticity: 0,
        basePriceScenario: {
            price: 0,
            margin: 0,
            competitiveness: 0,
            demandForecast: 0,
            revenueForecast: 0,
            profitForecast: 0,
            marginLevel1: 0,
            marginLevel2: 0,
        },
        newPriceScenario: {
            price: 0,
            margin: 0,
            competitiveness: 0,
            demandForecast: 0,
            revenueForecast: 0,
            profitForecast: 0,
            marginLevel1: 0,
            marginLevel2: 0,
        },
        scenariosVariation: {
            price: 0,
            margin: 0,
            competitiveness: 0,
            demandForecast: 0,
            revenueForecast: 0,
            profitForecast: 0,
            marginLevel1: 0,
            marginLevel2: 0,
        },
    },
    query: {
        productId: '',
        productName: '',
        storeIds: [],
        fromDate: '',
        toDate: '',
        newPrice: 0,
        basePrice: 0,
        client: '',
    },
    scenario: {
        id: null,
        scenarioName: null,
        user: null,
        username: null,
        client: null,
        date: null,
    },
    calculations: {
        hasElasticity: false,
        hasSimulation: false,
        isSearchDone: false,
    },
};

const calculateElasticity = (value: number) => {
    const absValue = Math.abs(Number(value));

    return Math.min(Math.max(absValue, 0), 4);
};

const slice = createSlice({
    name: 'demand-forecast-output-area',
    initialState,
    reducers: {
        setOutputs(
            state,
            { payload }: { payload: Omit<OutputAreaProps, 'calculations'> },
        ) {
            const hasElasticity = payload.output.elasticity !== null;

            state.simulation = snakeCaseToCamelCase<OutputSimulation[]>(
                payload.simulation,
            );

            const elasticity =
                payload.output.elasticity === null
                    ? null
                    : calculateElasticity(payload.output.elasticity);

            state.output = {
                ...snakeCaseToCamelCase<OutputSimulation>(payload.output),
                elasticity: elasticity,
            };

            state.scenario = snakeCaseToCamelCase<Scenario>(payload.scenario);

            state.query = snakeCaseToCamelCase<Query>(payload.query);

            state.calculations = {
                hasElasticity: hasElasticity,
                hasSimulation: payload.simulation.length > 0,
                isSearchDone: true,
            };
        },
        clearOutputData: () => initialState,
    },
});

export const { setOutputs, clearOutputData } = slice.actions;

export default slice.reducer;
