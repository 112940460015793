import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    Button,
    DateRangePicker, InputNumber,
    Tooltip,
    Whisper,
} from 'rsuite';
import {
    setGenerateReportData,
    setIsSpecificDates,
} from '../../../../../actions/actionsModuloRelatorio';
import calendarIcon from '../../../../../assets/icons/icon_calendar.svg';
import infoIcon from '../../../../../assets/icons/icon_info-gray.svg';
import DateRangePickerPill from '../../../../../components/DateRangePickerPill';
import SelectPill from '../../../../../components/SelectPill';
import {
    brokenPeriodRange,
    brokenPeriodRangeEnumType,
    getDateIntervals,
    locale,
    periodRange,
    setLocalDateISOFormat,
} from '../utils';

const maxDate = moment().toDate();
const minDate = moment().subtract(91, 'days').toDate();
const { combine, after, before } = DateRangePicker;

const DateIntervals = () => {
    const [active, setActive] = useState('7');
    const [intervalDays, setIntervalDays] = useState('0');
    const [activeBrokenPeriod, setActiveBrokenPeriod] = useState('Dias');
    const [dateRangeValue, setDateRangeValue] = useState([]);

    const { id } = useParams();

    const dispatch = useDispatch();

    const { moduloRelatorioData, showModalConfirmDownload, reportSaveSuccess }
        = useSelector(
            (state) => ({
                moduloRelatorioData:
                    state.moduloRelatorioDataReducer?.moduloRelatorioData,
                showModalConfirmDownload:
                    state.moduloRelatorioDataReducer?.showModalConfirmDownload,
                reportSaveSuccess:
                    state.moduloRelatorioDataReducer?.reportSaveSuccess,
            }),
            shallowEqual,
        );

    const handleDaysRange = (number) => {
        const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
        const day = new Date(yesterday - (+number - 1) * 24 * 60 * 60 * 1000);
        const activePill
            = number !== '7'
                && number !== '15'
                && number !== '30'
                && number !== '60'
                && number !== '90'
                ? '1'
                : number;

        setActive(activePill);
        setDateRangeValue([]);
        dispatch(
            setGenerateReportData('dataInicio', setLocalDateISOFormat(day)),
        );
        dispatch(
            setGenerateReportData('dataFim', setLocalDateISOFormat(yesterday)),
        );
        dispatch(setIsSpecificDates(false));
    };

    const handleDateRangePicker = (value) => {
        setActive('');
        setDateRangeValue(value);
        dispatch(
            setGenerateReportData('dataInicio', setLocalDateISOFormat(value[0])),
        );
        dispatch(
            setGenerateReportData('dataFim', setLocalDateISOFormat(value[1])),
        );
        dispatch(setIsSpecificDates(true));
    };

    const handleBrokenPeriod = (period) => {
        setActiveBrokenPeriod(period);
        dispatch(
            setGenerateReportData(
                'quebraPeriodo',
                period === 'Todo período'
                    ? 'TODO_PERIODO'
                    : period.toUpperCase(),
            ),
        );
    };

    const setIntervalDates = (dateA, dateB) => {
        const { isSpecificDates, diffDays } = getDateIntervals(dateA, dateB);

        if (isSpecificDates) {
            setActive('1');
            setIntervalDays(diffDays);
        } else {
            setActive(diffDays.toString());
            setDateRangeValue([]);
        }
    };

    const handleIntervalDays = (number) => {
        if (number > 0) {
            handleDaysRange(number);
        } else {
            setIntervalDates(
                moduloRelatorioData.dataInicio,
                moduloRelatorioData.dataFim,
            );
        }
    };

    useEffect(() => {
        handleDaysRange('7');
    }, []);

    useEffect(() => {
        if (typeof id === 'string') {
            setIntervalDates(
                moduloRelatorioData.dataInicio,
                moduloRelatorioData.dataFim,
            );

            setActiveBrokenPeriod(
                brokenPeriodRangeEnumType[moduloRelatorioData.quebraPeriodo],
            );
        }
    }, [id, moduloRelatorioData, active]);

    return (
        <>
            <div className="section-period">
                <div className="section-title-wrapper">
                    <p>Período</p>
                    <Whisper
                        placement="right"
                        speaker={(
                            <Tooltip>
                                Que será considerado para gerar o relatório
                                sendo 90 dias o máximo possível
                            </Tooltip>
                        )}
                    >
                        <img src={infoIcon} alt="" />
                    </Whisper>
                </div>
                <div className="buttons-wrapper">
                    {periodRange.map((item) => (
                        <Button
                            className={`inf-pill-button ${active === item ? 'active' : ''
                                }`}
                            key={item}
                            onClick={() => handleDaysRange(item)}
                        >
                            Últimos
                            {' '}
                            {item}
                            {' '}
                            dias
                        </Button>
                    ))}
                    {typeof id === 'string' ? (
                        <SelectPill
                            id="select-picker-pill"
                            className={`select-interval ${active === '1' ? 'active' : ''
                                }`}
                            menuClassName="select-interval-menu"
                            placeholder="Intervalo personalizado"
                            searchable={false}
                            onClose={() => handleIntervalDays(intervalDays)}
                            renderExtraFooter={() => (
                                <div className="select-interval-input-wrapper">
                                    <p>Últimos</p>
                                    <InputNumber
                                        min={0}
                                        value={intervalDays}
                                        onChange={(value) => setIntervalDays(value)}
                                    />
                                    <p>dias</p>
                                </div>
                            )}
                        />
                    ) : (
                        <DateRangePickerPill
                            block
                            name="date-range"
                            placement="bottomEnd"
                            cleanable
                            classNameValue={
                                dateRangeValue.length
                                    ? 'date-range-has-value'
                                    : ''
                            }
                            value={dateRangeValue}
                            onChange={(value) => handleDateRangePicker(value)}
                            onClean={() => handleDaysRange('7')}
                            disabledDate={
                                maxDate || minDate
                                    ? combine(before(minDate), after(maxDate))
                                    : null
                            }
                            appearance="default"
                            placeholder="Selecione datas específicas"
                            format="DD/MM/YYYY"
                            locale={locale}
                            ranges={[]}
                            leftIcon={(
                                <img
                                    src={calendarIcon}
                                    alt=""
                                    style={{ height: '12px' }}
                                />
                            )}
                        />
                    )}
                </div>
            </div>
            <div>
                <div className="section-title-wrapper">
                    <p>Quebra de período</p>
                    <Whisper
                        placement="right"
                        speaker={(
                            <Tooltip>
                                Como a coluna Período virá no relatório: se por
                                dias ou agrupado por semanas ou meses
                            </Tooltip>
                        )}
                    >
                        <img src={infoIcon} alt="" />
                    </Whisper>
                </div>
                <div className="buttons-wrapper">
                    {brokenPeriodRange.map((item) => (
                        <Button
                            className={`inf-pill-button ${activeBrokenPeriod === item ? 'active' : ''
                                }`}
                            key={item}
                            onClick={() => handleBrokenPeriod(item)}
                        >
                            {item}
                        </Button>
                    ))}
                </div>
            </div>
        </>
    );
};

export default DateIntervals;
