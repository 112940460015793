import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataItem } from '../../../../@types/DataItem';
import { PriceExtraction } from '../../../../@types/IPA/PriceExtraction';
import { RootState } from '../../../../@types/RootState';

type K = PriceExtraction['filtersKeys'];

type T = DataItem[];

type State = Partial<Record<K, T>>;

const initialState: State = {};

const slice = createSlice({
    name: 'ipa.extraction.filters.data',
    initialState,
    reducers: {
        RESET_EXTRACTION_FILTERS_CACHE: () => initialState,
        SET_EXTRACTION_FILTERS_CACHE: (_, action: PayloadAction<State>) => action.payload,
        SET_EXTRACTION_FILTERS_CACHE_BY_KEY: (
            state,
            action: PayloadAction<{
                key: K;
                data: T;
            }>,
        ) => {
            return {
                ...state,
                [action.payload.key]: action.payload.data,
            };
        },
    },
});

export const { RESET_EXTRACTION_FILTERS_CACHE, SET_EXTRACTION_FILTERS_CACHE_BY_KEY } = slice.actions;

export default slice.reducer;

export const selectorIpaExtractionFiltersCache = (state: RootState) => state.ipa.extraction.filters.cache;
