import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col, Icon, Nav, Popover, Row, Tooltip, Whisper } from 'rsuite';

import {
    addFiltro,
    getItensFiltro,
    searchEndereco,
} from '../../../../../actions/actionsPainelGeral';

import crownIcon from '../../../../../assets/icons/icon_crown.svg';
import infoIcon from '../../../../../assets/icons/icon_info_gray.svg';
import lockIcon from '../../../../../assets/icons/icon_lock-gray.svg';
import poweredByShoppingBrasil from '../../../../../assets/shoppingBrasil/powered_by_shopping_brasil.svg';
import { getModelPanel } from '../utils';

export function shouldBlockMenuItem(navType, pacote, shoppingBrasilPlan, item) {
    if (navType === 'ANÚNCIOS' && !shoppingBrasilPlan) {
        return item === 'mecanica' || item === 'grupoMidia' || item === 'midia';
    }

    if (navType !== 'ANÚNCIOS' && pacote === 'FREE') {
        return (
            item === 'rede' ||
            item === 'uf' ||
            item === 'cidade' ||
            item === 'endereco' ||
            item === 'fabricante' ||
            item === 'marca' ||
            item === 'conteudo' ||
            item === 'origemPreco'
        );
    }

    return false;
}

const CustomNav = ({
    locked,
    title,
    icon,
    itens,
    onSelect,
    pacote,
    shoppingBrasilPlan,
    ...props
}) => (
    <Nav
        {...props}
        vertical
        onSelect={onSelect}
        style={{ width: '100' }}
        className={`
            ${title === 'ANÚNCIOS' && !shoppingBrasilPlan ? 'shopping-brasil-locked' : ''}
            ${
                title === 'ANÚNCIOS' && shoppingBrasilPlan
                    ? 'shopping-brasil'
                    : ''
            } nav-filtro-dinamico
        `}
    >
        <div className="nav-filtro-dinamico__title-div">
            {title === 'ANÚNCIOS' ? (
                <img src={crownIcon} alt="" />
            ) : (
                <Icon icon={icon} />
            )}
            <p className="nav-filtro-dinamico__title">{title}</p>
            {title === 'ANÚNCIOS' && !shoppingBrasilPlan ? (
                <Whisper
                    speaker={
                        <Tooltip>
                            Adicione informações de anúncios promocionais na sua
                            análise. Você pode filtrar usando dinâmicas
                            específicas dos anúncios.
                        </Tooltip>
                    }
                >
                    <img src={infoIcon} alt="" className="info-icon" />
                </Whisper>
            ) : null}
            {title === 'ANÚNCIOS' && !shoppingBrasilPlan ? (
                <img src={lockIcon} alt="" className="info-icon" />
            ) : null}
        </div>

        {itens?.map((item) => (
            <Nav.Item
                className="nav-item"
                disabled={shouldBlockMenuItem(
                    title,
                    pacote?.name,
                    shoppingBrasilPlan,
                    item.name,
                )}
                title={
                    shouldBlockMenuItem(
                        title,
                        pacote?.name,
                        shoppingBrasilPlan,
                        item.name,
                    )
                        ? 'Contrate para liberar todos os filtros'
                        : null
                }
                key={item.name}
                name={item.name}
                eventKey={item.name}
            >
                <span>{item.title}</span>
                {shouldBlockMenuItem(
                    title,
                    pacote?.name,
                    shoppingBrasilPlan,
                    item.name,
                ) ? (
                    <span className="nav-item__block-icon">
                        <Icon icon="lock" />
                    </span>
                ) : null}
            </Nav.Item>
        ))}

        {title === 'ANÚNCIOS' ? (
            <div className="shopping-brasil-logo">
                <img src={poweredByShoppingBrasil} alt="" />
            </div>
        ) : null}
    </Nav>
);

class Speaker extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        return nextProps !== this.props;
    }

    async handleClick(eventKey) {
        const {
            filtrosAdicionados,
            searchEndereco,
            getItensFiltro,
            addFiltro,
            filtros,
        } = this.props;

        if (
            !filtrosAdicionados.length ||
            (filtrosAdicionados.length &&
                filtrosAdicionados.findIndex(
                    (item) => item.name === eventKey,
                ) === -1)
        ) {
            if (eventKey === 'endereco') {
                await searchEndereco('');
            } else if (eventKey === 'cnpj' || eventKey === 'conteudo') {
                const name =
                    eventKey || 'gramatura_descricao';
                const data = getModelPanel(this.props, '', name);
                await getItensFiltro(data, eventKey, 'painel');
            } else {
                const data = getModelPanel(this.props, '', eventKey);
                await getItensFiltro(data, eventKey, 'painel');
            }
            addFiltro(
                filtros[filtros.findIndex((item) => item.name === eventKey)],
            );
        }
    }

    render() {
        const { tiposFiltro, usuario } = this.props;

        return (
            <Popover {...this.props}>
                <Row style={{ width: '600px' }}>
                    {tiposFiltro?.map((item) => (
                        <Col
                            md={8}
                            key={item.name}
                            style={{ marginBottom: '15px' }}
                            className={
                                item.title === 'LOCALIZAÇÃO'
                                    ? 'location-margin-right'
                                    : ''
                            }
                        >
                            <CustomNav
                                locked={
                                    !usuario?.tipoAdicionalContratos?.PAN?.includes(
                                        'SB',
                                    )
                                }
                                title={item.title}
                                icon={item.icon}
                                itens={item.itens}
                                onSelect={this.handleClick}
                                pacote={usuario?.servicoPanel.pacotePainel}
                                shoppingBrasilPlan={usuario?.tipoAdicionalContratos?.PAN?.includes(
                                    'SB',
                                )}
                            />
                        </Col>
                    ))}
                </Row>
            </Popover>
        );
    }
}

const mapStateToProps = (store) => ({
    tiposFiltro: store.painelGeralDataReducer.tiposFiltro,
    filtros: store.painelGeralDataReducer.filtros,
    filtrosAdicionados: store.painelGeralDataReducer.filtrosAdicionados,
    produto: store.painelGeralDataReducer.produto,
    rede: store.painelGeralDataReducer.rede,
    tipoLoja: store.painelGeralDataReducer.tipoLoja,
    cnpj: store.painelGeralDataReducer.cnpj,
    fabricante: store.painelGeralDataReducer.fabricante,
    marca: store.painelGeralDataReducer.marca,
    conteudo: store.painelGeralDataReducer.conteudo,
    tipoProduto: store.painelGeralDataReducer.tipoProduto,
    secao: store.painelGeralDataReducer.secao,
    categoria: store.painelGeralDataReducer.categoria,
    mecanica: store.painelGeralDataReducer.mecanica,
    grupoMidia: store.painelGeralDataReducer.grupoMidia,
    midia: store.painelGeralDataReducer.midia,
    uf: store.painelGeralDataReducer.uf,
    cidade: store.painelGeralDataReducer.cidade,
    endereco: store.painelGeralDataReducer.endereco,
    tipoPreco: store.painelGeralDataReducer.tipoPreco,
    origemPreco: store.painelGeralDataReducer.origemPreco,
    dataInicio: store.painelGeralDataReducer.dataInicio,
    dataFim: store.painelGeralDataReducer.dataFim,
    raio: store.painelGeralDataReducer.raio,
    preco: store.painelGeralDataReducer.preco,
    canal: store.painelGeralDataReducer.canal,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            addFiltro,
            getItensFiltro,
            searchEndereco,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Speaker);
