import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GerenciadorPrecos } from '../../../../@types/GerenciadorPrecos';
import { Sort } from '../../../../@types/sort';
import { RESET_GERENCIADOR_DATAKEY, selectorGerenciadorDatakeys, UPDATE_GERENCIADOR_DATAKEY } from '../../../../reducers';
import { initialState, selectorSort, SET_GERENCIADOR_SORT } from '../../../../reducers/gerenciadorPrecos/sort';
import { LocalStorage } from '../../../../utils';
import { sortOptionsColumnMap } from '../sortOptions';

const LOCAL_STORAGE_KEY = 'ipa.gerenciador.sort';
const LOCAL_STORAGE_DATAKEY = 'ipa.gerenciador.datakey';

const useGerenciadorSort = () => {
    const dispatch = useDispatch();

    const datakeys = useSelector(selectorGerenciadorDatakeys);

    const sort = useSelector(selectorSort);

    const handleUpdateDataKeys = useCallback(
        (column: string, value: GerenciadorPrecos.DataKey) => {
            const newColumn = sortOptionsColumnMap[column] || column;
            const newDataKey = { [newColumn]: value };
            dispatch(RESET_GERENCIADOR_DATAKEY());
            dispatch(UPDATE_GERENCIADOR_DATAKEY(newDataKey));
            LocalStorage.set(LOCAL_STORAGE_DATAKEY, newDataKey);
        },
        [dispatch],
    );

    const onSortColumn = useCallback(
        (type: GerenciadorPrecos.DataKey, orderBy: Sort['orderBy']) => {
            const newSort = { type, orderBy };
            dispatch(SET_GERENCIADOR_SORT(newSort));
            LocalStorage.set(LOCAL_STORAGE_KEY, newSort);
        },
        [dispatch],
    );

    const onUpdateSortColumn = useCallback(
        (column: string, value: GerenciadorPrecos.DataKey) => {
            onSortColumn(value, sort.orderBy || 'asc');
            handleUpdateDataKeys(column, value);
        },
        [sort.orderBy, onSortColumn, handleUpdateDataKeys],
    );

    useEffect(() => {
        const sortLocal = LocalStorage.get<typeof sort>(LOCAL_STORAGE_KEY);
        if (sortLocal) dispatch(SET_GERENCIADOR_SORT(sortLocal));
    }, [dispatch]);

    useEffect(() => {
        const dataKeyLocal = LocalStorage.get<Partial<typeof datakeys>>(LOCAL_STORAGE_DATAKEY);
        if (dataKeyLocal) dispatch(UPDATE_GERENCIADOR_DATAKEY(dataKeyLocal));
    }, [dispatch]);

    return {
        datakeys,
        sort,
        initialState,
        onSortColumn,
        onUpdateSortColumn,
    } as const;
};

export default useGerenciadorSort;
