import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GerenciadorPrecos } from '../../@types/GerenciadorPrecos';
import { RootState } from '../../@types/RootState';
import { Sort as DefaultSort } from '../../@types/sort';

type DataKey = GerenciadorPrecos.DataKey;

type Sort = DefaultSort<DataKey>;

const initialState: DefaultSort<DataKey> = {
    type: 'competitorsPrice',
    orderBy: 'asc',
};

const slice = createSlice({
    name: 'ipa/gerenciador/sort',
    initialState,
    reducers: {
        SET_GERENCIADOR_SORT(state, { payload }: PayloadAction<Sort>) {
            state.type = payload.type;
            state.orderBy = payload.orderBy;
        },
        UPDATE_GERENCIADOR_SORT_COLUMN: (state, { payload }: PayloadAction<DataKey>) => {
            state.type = payload;
        },
        UPDATE_GERENCIADOR_SORT_ORDER_BY: (state, { payload }: PayloadAction<Sort['orderBy']>) => {
            state.orderBy = payload;
        },
    },
});

const selectorSort = (state: RootState) => {
    return state.gerenciadorPrecosReducer.sort;
};

const { SET_GERENCIADOR_SORT, UPDATE_GERENCIADOR_SORT_ORDER_BY, UPDATE_GERENCIADOR_SORT_COLUMN } = slice.actions;

export { SET_GERENCIADOR_SORT, UPDATE_GERENCIADOR_SORT_COLUMN, UPDATE_GERENCIADOR_SORT_ORDER_BY, initialState, selectorSort };

export default slice.reducer;
