import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { Table as RSTable } from 'rsuite';
import { PriceExtraction } from '../../../../../@types/IPA/PriceExtraction';
import { BaseCell } from '../../../../../components';
import { Tag } from '../../../Lite';
import { useTable } from './useTable';

type RowData = PriceExtraction['rowData'];

const { Column, HeaderCell, Cell, Pagination } = RSTable;

export const Table = () => {
    const { datapoints, pagination, isLoading, actions } = useTable();
    return (
        <>
            <RSTable data={datapoints} headerHeight={46} rowHeight={46} loading={isLoading} height={400} autoHeight>
                {/* @ts-ignore */}
                <Column flexGrow={3}>
                    <HeaderCell>Produto</HeaderCell>
                    <Cell>
                        {(rowData: RowData) => (
                            <BaseCell>
                                <span>
                                    <strong>{rowData.productId}</strong> - {rowData.description}
                                </span>
                            </BaseCell>
                        )}
                    </Cell>
                </Column>
                {/* @ts-ignore */}
                <Column flexGrow={2}>
                    <HeaderCell>Loja</HeaderCell>
                    <Cell>{(rowData: RowData) => <BaseCell>{rowData.storeName}</BaseCell>}</Cell>
                </Column>

                {/* @ts-ignore */}
                <Column flexGrow={1}>
                    <HeaderCell>Preço regular</HeaderCell>
                    <Cell>
                        {(rowData: RowData) => (
                            <BaseCell>
                                <BaseCell.MainContainer>
                                    <CurrencyFormat
                                        fixedDecimalScale
                                        decimalScale={2}
                                        value={rowData.price ?? ''}
                                        displayType="text"
                                        prefix="R$"
                                        decimalSeparator=","
                                        thousandSeparator="."
                                    />
                                </BaseCell.MainContainer>
                            </BaseCell>
                        )}
                    </Cell>
                </Column>
                {/* @ts-ignore */}
                <Column flexGrow={1}>
                    <HeaderCell>Preço Atacado</HeaderCell>
                    <Cell>
                        {(rowData: RowData) => (
                            <BaseCell>
                                <BaseCell.MainContainer>
                                    <CurrencyFormat
                                        fixedDecimalScale
                                        decimalScale={2}
                                        value={rowData?.wholesale?.price ?? ''}
                                        displayType="text"
                                        prefix="R$"
                                        decimalSeparator=","
                                        thousandSeparator="."
                                    />
                                </BaseCell.MainContainer>
                            </BaseCell>
                        )}
                    </Cell>
                </Column>
                {/* @ts-ignore */}
                <Column flexGrow={1} verticalAlign="middle">
                    <HeaderCell>Tipo de preço</HeaderCell>
                    <Cell>{(rowData: RowData) => <Tag skin="blue">{rowData.priceType}</Tag>}</Cell>
                </Column>
            </RSTable>
            <Pagination {...pagination} onChangeLength={actions.handleChangePageSize} onChangePage={actions.handleChangePage} />
        </>
    );
};
