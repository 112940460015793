import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PriceExtraction } from '../../../../@types/IPA/PriceExtraction';
import { RootState } from '../../../../@types/RootState';

type K = PriceExtraction['filtersKeys'];

type T = string;

type State = Partial<Record<K, T>>;

const initialState: State = {};

const slice = createSlice({
    name: 'ipa.extraction.filters.value',
    initialState,
    reducers: {
        RESET_EXTRACTION_FILTERS_QUERIES: () => initialState,
        SET_EXTRACTION_FILTERS_QUERIES: (_, action: PayloadAction<State>) => action.payload,
        SET_EXTRACTION_FILTERS_QUERIES_BY_KEY: (state, action: PayloadAction<{ key: K; query: T }>) => {
            return { ...state, [action.payload.key]: action.payload.query };
        },
    },
});

export const { RESET_EXTRACTION_FILTERS_QUERIES, SET_EXTRACTION_FILTERS_QUERIES_BY_KEY } = slice.actions;

export default slice.reducer;

export const selectorIpaExtractionFiltersQueries = (state: RootState) => state.ipa.extraction.filters.queries;
