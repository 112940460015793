import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';

import api from '../../../utils/API';

export const getDatapointsExportacao = (params) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/all-products`,
            method: 'GET',
            expectToken: true,
            params: {
                page: params.page || 0,
                size: params.size || 20,
                productIds: params?.productIds,
                storeIds: params?.storeIds,
                ruleType: params?.ruleType,
                categoryLevel1: params?.categoryLevel1,
                categoryLevel2: params?.categoryLevel2,
                categoryLevel3: params?.categoryLevel3,
                categoryLevel4: params?.categoryLevel4,
                categoryLevel5: params?.categoryLevel5,
                categoryLevel6: params?.categoryLevel6,
                categoryLevel7: params?.categoryLevel7,
                categoryLevel: params?.categoryLevel,
                field: params?.field,
                applied: true,
                ...params,
            },
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp.data),
    );

export const datapointsDownload = (params) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/all-products/download`,
            method: 'GET',
            expectToken: true,
            params: {
                productIds: params?.params.productIds,
                storeIds: params?.params.storeIds,
                categoryLevel1: params?.params.categoryLevel1,
                categoryLevel2: params?.params.categoryLevel2,
                categoryLevel3: params?.params.categoryLevel3,
                categoryLevel4: params?.params.categoryLevel4,
                categoryLevel5: params?.params.categoryLevel5,
                categoryLevel6: params?.params.categoryLevel6,
                categoryLevel7: params?.params.categoryLevel7,
                categoryLevel: params?.params.categoryLevel,
                applied: true,
            },
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
            responseType: 'arraybuffer',
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                }
                return res;
            })
            .then((resp) => {
                let download = resp;
                if (!download.status) {
                    const blob = new Blob([download]);
                    const hiddenElement = document.createElement('a');
                    hiddenElement.href = window.URL.createObjectURL(blob);
                    hiddenElement.target = '_blank';
                    hiddenElement.download = 'extracao_precos.csv';
                    hiddenElement.click();
                    download = null;
                }
                return download;
            }),
    );
