import classNames from 'classnames';
import React, { forwardRef, SyntheticEvent } from 'react';
import { RadioGroupProps } from 'rsuite/lib/RadioGroup';
import { InputBox } from '../InfoInput';
import styles from './CustomHeaderCell.module.scss';

export type CustomHeaderCellContentDataListProps = RadioGroupProps<string> & {
    dataKey: string;
    data?: DataItem[];
    onChangeSort?: (dataKey:string, value: string) => void;
};

const CustomHeaderCellContentDataList = forwardRef<HTMLDivElement, CustomHeaderCellContentDataListProps>(
    ({ className, dataKey, data = [], onChangeSort, onChange, ...props }, ref) => {
        const handleChange = (value: string, event: SyntheticEvent<HTMLElement>) => {
            onChangeSort?.(dataKey,value);
            onChange?.(value, event);
        };

        return (
            <InputBox.RadioGroup {...props} ref={ref} className={classNames(styles['content-data-list'], className)} onChange={handleChange}>
                {data.map(({ value, label }) => (
                    <InputBox.Radio key={value} className={styles['content-data-list__item']} value={value}>
                        {label}
                    </InputBox.Radio>
                ))}
            </InputBox.RadioGroup>
        );
    },
);

CustomHeaderCellContentDataList.displayName = 'CustomHeaderCellContentDataList';

export { CustomHeaderCellContentDataList };
