import { useQueries, useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataItem } from '../../../../../@types/DataItem';
import { PriceExtraction } from '../../../../../@types/IPA/PriceExtraction';
import * as service from '../../../../../components/FilterSection/services';
import { useDebounce } from '../../../../../hooks';
import { selectorSegmentos } from '../../../../../reducers/gerenciadorPrecos/segmentos';
import {
    RESET_EXTRACTION_FILTERS_CACHE,
    selectorIpaExtractionFiltersCache,
    SET_EXTRACTION_FILTERS_CACHE_BY_KEY,
} from '../../../../../reducers/ipa/extraction/filters/cache';
import { selectorIpaExtractionFiltersData, SET_EXTRACTION_FILTERS_DATA_BY_KEY } from '../../../../../reducers/ipa/extraction/filters/data';
import {
    RESET_EXTRACTION_FILTERS_QUERIES,
    selectorIpaExtractionFiltersQueries,
    SET_EXTRACTION_FILTERS_QUERIES_BY_KEY,
} from '../../../../../reducers/ipa/extraction/filters/queries';
import {
    RESET_EXTRACTION_FILTERS_VALUE,
    selectorIpaExtractionFiltersValues,
    SET_EXTRACTION_FILTERS_VALUE_BY_KEY,
} from '../../../../../reducers/ipa/extraction/filters/values';
import { checkIfIsLoading } from '../../../../../utils';

type Key = PriceExtraction['filtersKeys'];

export const useFilters = () => {
    const dispatch = useDispatch();
    const debounce = useDebounce();
    const data = useSelector(selectorIpaExtractionFiltersData);
    const value = useSelector(selectorIpaExtractionFiltersValues);
    const queries = useSelector(selectorIpaExtractionFiltersQueries);
    const cache = useSelector(selectorIpaExtractionFiltersCache);
    const cattegories = useSelector(selectorSegmentos);

    const handleSuccess = useCallback(
        (key: Key, data: Array<DataItem | service.SegmentData>) => {
            const newData = _.uniqBy([...(cache[key] ?? []), ...data], 'value');
            dispatch(SET_EXTRACTION_FILTERS_DATA_BY_KEY({ key, data: newData as DataItem[] }));
        },
        [dispatch, cache],
    );

    const { fetchStatus: loadingProducts } = useQuery({
        queryKey: ['ipa.extraction.filters.productIds', queries.productIds],
        queryFn: () => service.listProducts(queries?.productIds),
        onSuccess: (data) => handleSuccess('productIds', data),
    });

    const { fetchStatus: loadingProductFamilyIds } = useQuery({
        queryKey: ['ipa.filter.productFamilyIds', queries.productFamilyIds],
        queryFn: () => service.listFamilies(queries.productFamilyIds),
        onSuccess: (data) => handleSuccess('productFamilyIds', data),
    });

    const { fetchStatus: loadingStoreIds } = useQuery({
        queryKey: ['ipa.filter.storeIds', queries.storeIds],
        queryFn: () => service.listStores(queries.storeIds),
        onSuccess: (data) => handleSuccess('storeIds', data),
    });

    const { fetchStatus: loadingSegmentos } = useQuery({
        queryKey: ['ipa.filter.segmentos'],
        queryFn: service.listaSegmentacao,
        onSuccess: (data) => handleSuccess('segmentos', data),
    });

    const handleSearch = useCallback(
        (key: Key, query: string) => {
            debounce(() => dispatch(SET_EXTRACTION_FILTERS_QUERIES_BY_KEY({ key, query })));
        },
        [dispatch, debounce],
    );

    const handleClean = useCallback(
        (key: Key) => {
            dispatch(SET_EXTRACTION_FILTERS_QUERIES_BY_KEY({ key, query: '' }));
            dispatch(SET_EXTRACTION_FILTERS_VALUE_BY_KEY({ key, value: [] }));
            dispatch(SET_EXTRACTION_FILTERS_CACHE_BY_KEY({ key, data: [] }));
        },
        [dispatch],
    );

    const handleCleanAll = useCallback(() => {
        dispatch(RESET_EXTRACTION_FILTERS_CACHE());
        dispatch(RESET_EXTRACTION_FILTERS_VALUE());
        dispatch(RESET_EXTRACTION_FILTERS_QUERIES());
    }, [dispatch]);

    const handleChange = useCallback(
        (key: Key, value: string[]) => {
            dispatch(SET_EXTRACTION_FILTERS_VALUE_BY_KEY({ key, value }));
            if (key === 'segmentos') return;
            const cache = data[key]?.filter((item) => value.includes(item.value));
            dispatch(SET_EXTRACTION_FILTERS_CACHE_BY_KEY({ key, data: cache ?? [] }));
        },
        [dispatch, data],
    );

    const handleSelectAll = useCallback(
        (key: Key, checked: boolean) => {
            if (key === 'segmentos') return;
            let newData: string[] = [];
            if (checked) {
                newData = data[key]?.map(({ value }) => value) ?? [];
            }
            dispatch(SET_EXTRACTION_FILTERS_VALUE_BY_KEY({ key, value: newData }));
        },
        [dispatch, data],
    );

    const results = useQueries({
        queries: cattegories.map(({ value: key, level }) => ({
            queryKey: ['ipa.filter.segmentos', key, queries[key]],
            queryFn: () => service.listSegment(queries[key], level),
            onSuccess: (data: DataItem[]) => handleSuccess(key, data),
            enabled: !!cattegories.length,
        })),
    });

    const loading = useMemo(
        () => ({
            productIds: checkIfIsLoading(loadingProducts),
            storeIds: checkIfIsLoading(loadingStoreIds),
            productFamilyIds: checkIfIsLoading(loadingProductFamilyIds),
            segmentos: checkIfIsLoading(loadingSegmentos),
            cattegories: results.some(({ fetchStatus }) => checkIfIsLoading(fetchStatus)),
        }),
        [loadingProducts, loadingStoreIds, loadingProductFamilyIds, loadingSegmentos, results],
    );

    return { data, loading, value, cattegories, actions: { handleSearch, handleClean, handleChange, handleCleanAll, handleSelectAll } };
};
