import React, { ComponentProps, memo } from 'react';
import { withRouter } from 'react-router-dom';
import { PageProps } from '../../../@types';
import { Layout } from '../../../components';
import { Filters, Header, Modals, Table, TableHeading } from './components';

const MemoFilters = memo(Filters);
const MemoHeader = memo(Header);
const MemoTableHeading = memo(TableHeading);
const MemoTable = memo(Table);
const MemoModals = memo(Modals);

export type PriceExtractionProps = PageProps<ComponentProps<'div'>>;

const PriceExtraction = (props: PriceExtractionProps) => {
    return (
        <>
            <Layout {...props}>
                <MemoHeader />
                <Layout.Body>
                    <Layout.Section padded>
                        <MemoFilters />
                    </Layout.Section>
                    <Layout.Section>
                        <MemoTableHeading />
                    </Layout.Section>
                    <Layout.Section>
                        <MemoTable />
                    </Layout.Section>
                </Layout.Body>
            </Layout>
            <MemoModals />
        </>
    );
};

export default withRouter(PriceExtraction);
