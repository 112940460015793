import classNames from 'classnames';
import React, { forwardRef, useCallback, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import styles from './CustomHeaderCell.module.scss';
import { CustomHeaderCellContent, CustomHeaderCellContentProps } from './CustomHeaderCellContent';
import { CustomHeaderCellSortTrigger } from './CustomHeaderCellSortTrigger';

const CustomHeaderCell = forwardRef<HTMLDivElement, CustomHeaderCellContentProps>(({ className, children, data = [], ...props }, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleIsOpen = useCallback(() => setIsOpen((prev) => !prev), []);

    return (
        <div ref={ref} className={classNames(styles.wrapper, className)}>
            <span>{children}</span>
            {!!data.length && (
                <span onClick={(e) => e.stopPropagation()}>
                    <Popover
                        positions={['bottom', 'right']}
                        align="start"
                        isOpen={isOpen}
                        padding={4}
                        onClickOutside={handleToggleIsOpen}
                        content={<CustomHeaderCellContent data={data} {...props} />}
                        clickOutsideCapture
                    >
                        <CustomHeaderCellSortTrigger onClick={handleToggleIsOpen} />
                    </Popover>
                </span>
            )}
        </div>
    );
});

CustomHeaderCell.displayName = 'CustomHeaderCell';

export { CustomHeaderCell };
