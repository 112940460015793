import classNames from 'classnames';
import React, { ComponentProps, forwardRef } from 'react';
import { MdTune } from 'react-icons/md';
import styles from './CustomHeaderCell.module.scss';

type CustomHeaderCellSortTriggerProps = ComponentProps<'button'>;

const CustomHeaderCellSortTrigger = forwardRef<HTMLButtonElement, CustomHeaderCellSortTriggerProps>(({ className, onClick, ...props }, ref) => {
    return (
        <button
            {...props}
            ref={ref}
            className={classNames(styles.trigger, className)}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClick?.(e);
            }}
        >
            <MdTune size={12} />
        </button>
    );
});

CustomHeaderCellSortTrigger.displayName = 'CustomHeaderCellSortTrigger';

export { CustomHeaderCellSortTrigger };
