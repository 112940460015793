import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PriceExtraction } from '../../../../@types/IPA/PriceExtraction';
import { RootState } from '../../../../@types/RootState';

type Key = PriceExtraction['filtersKeys'];

type KeyWithoutSegment = Exclude<Key, 'segmentos'>;

type Data = string[];

type SegmentData = {
    type: string;
    value: string;
};

export type ExtractionFiltersValuesState = Partial<Record<KeyWithoutSegment, string[]> & Record<'segmentos', SegmentData[]>>;

const initialState: ExtractionFiltersValuesState = {};

const slice = createSlice({
    name: 'ipa.extraction.filters.value',
    initialState,
    reducers: {
        RESET_EXTRACTION_FILTERS_VALUE: () => initialState,
        SET_EXTRACTION_FILTERS_VALUE: (_, action: PayloadAction<ExtractionFiltersValuesState>) => action.payload,
        SET_EXTRACTION_FILTERS_VALUE_BY_KEY: (state, action: PayloadAction<{ key: Key; value: Data }>) => {
            return { ...state, [action.payload.key]: action.payload.value };
        },
    },
});

export const { RESET_EXTRACTION_FILTERS_VALUE, SET_EXTRACTION_FILTERS_VALUE_BY_KEY } = slice.actions;

export default slice.reducer;
export const selectorIpaExtractionFiltersValues = (state: RootState) => state.ipa.extraction.filters.values;
