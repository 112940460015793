import { ExtractionFiltersValuesState } from '../../../../../reducers/ipa/extraction/filters/values';
import { ExtractionPaginationState } from '../../../../../reducers/ipa/extraction/table/pagination';
import { getValidData } from '../../../RevisaoPrecos/utils';

export const GET_extractionDatapointsMapper = ({ value, pagination }: { value: ExtractionFiltersValuesState; pagination?: ExtractionPaginationState }) => {
    return getValidData({
        ...value,
        page: (pagination?.activePage ?? 1) - 1,
        size: pagination?.displayLength,
        appied: true,
    });
};
