import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataItem } from '../../../../@types/DataItem';
import { PriceExtraction } from '../../../../@types/IPA/PriceExtraction';
import { RootState } from '../../../../@types/RootState';
import { SegmentData } from '../../../../components/FilterSection/services';

type Key = PriceExtraction['filtersKeys'];

type KeyWithoutSegment = Exclude<Key, 'segmentos'>;

type Data = DataItem[];

type State = Partial<Record<KeyWithoutSegment, Data> & Record<'segmentos', SegmentData[]>>;

const initialState: State = {};

const slice = createSlice({
    name: 'ipa.extraction.filters.data',
    initialState,
    reducers: {
        RESET_EXTRACTION_FILTERS_DATA: () => initialState,
        SET_EXTRACTION_FILTERS_DATA: (_, action: PayloadAction<State>) => action.payload,
        SET_EXTRACTION_FILTERS_DATA_BY_KEY: (
            state,
            action: PayloadAction<{
                key: Key;
                data: Data;
            }>,
        ) => {
            return {
                ...state,
                [action.payload.key]: action.payload.data,
            };
        },
    },
});

export const { RESET_EXTRACTION_FILTERS_DATA, SET_EXTRACTION_FILTERS_DATA_BY_KEY } = slice.actions;

export default slice.reducer;

export const selectorIpaExtractionFiltersData = (state: RootState) => state.ipa.extraction.filters.data;
